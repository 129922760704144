import ClientSectionHeader from '../Others/ClientSectionHeader';
// import { ReactComponent as PlusIcon } from '../../assets/icons/moreIcon.svg';
import DropDown from '../Inputs/DropDown';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as AccountingActions from '../../redux/actions/integrations/accounting-actions';
import { CircularProgress } from '@mui/material';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

export default function SelectCustomerModal({ onCancel, options, invoiceId, setCustomers }) {
	const dispatch = useDispatch();
	const integration = useSelector((state) => state.AccountingReducer.integration);
	const [customerData, setCustomerData] = useState({});
	const [loading, setLoading] = useState(false);
	const [customersLoading, setCustomersLoading] = useState(false);

	const handleDropDown = (e) => {
		const { name, value } = e.target;
		setCustomerData({
			[name]: value,
		});
	};

	const selectedCustomer = options?.find((item) => item?.value === customerData?.customer_data);

	const handleGenerateInvoice = () => {
		setLoading(true);
		dispatch(AccountingActions.createJoyboiInvoice(selectedCustomer?.value, invoiceId))
			.then(() => {
				dispatch(setSnackBarIsOpen(true, true, 'Successfully generated invoice!'));
			})
			.catch(() => {
				dispatch(setSnackBarIsOpen(false, true, 'Failed to generate invoice'));
			})
			.finally(() => {
				setLoading(false);
				onCancel();
			});
	};

	useEffect(() => {
		if (integration?.data?.some((temp) => temp?.connections?.length > 0)) {
			setCustomersLoading(true);
			dispatch(AccountingActions.getCustomers()).then((response) => {
				if (response?.data?.length) {
					const tempArr = response?.data?.map((item) => {
						return {
							value: item?.id,
							label: item?.customer_name ? item?.customer_name : item?.contact_name,
						};
					});
					setCustomers(tempArr);
                    setCustomersLoading(false);
				}
			}).catch(() => {
                setCustomersLoading(false);
            });
			
		}
	}, [integration, dispatch]);

	return (
		<div>
			<div className='Header'>
				<ClientSectionHeader
					title='Select Customer'
					border={false}
					// subheader={
					// 	<>
					// 		<PlusIcon /> Add New Customer
					// 	</>
					// }
					// subHeaderStyle={{ color: '#FB7A03' }}
					style={{ width: '100%' }}
				/>
			</div>
			{customersLoading ? (
                <div className='d-flex align-items-center justify-content-center pb-5'>
				<CircularProgress
					size={50}
					sx={{ color: '#FB7A03', marginTop: '20px' }}
					disableShrink
				/>
                </div>
			) : (
				<div style={{ padding: '0px 24px 24px 24px' }}>
					<div>
						<span className='BodyTwoLight'>
							Please select customer to sync data between Quickbooks and Levvy
						</span>
					</div>
					<div className='pt-2'>
						<label className='BodyTwoLight'>Customers List</label>
						<DropDown
							// label='Customers List'
							options={options}
							name='customer_data'
							value={selectedCustomer}
							onChange={handleDropDown}
						/>
					</div>
				</div>
			)}
			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						type='button'
						className='secondary'
						style={{ borderRadius: '50px', width: '125px' }}
						onClick={onCancel}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						style={{ borderRadius: '50px', width: 'auto', opacity: (loading || customersLoading) ? 0.5 : 1 }}
						id='Element'
						data-cy='add-client-btn'
						onClick={handleGenerateInvoice}
						disabled={loading || customersLoading}
					>
						{loading ? (
							<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
						) : (
							'Generate'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</div>
	);
}
