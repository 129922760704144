import { ACTION_SIDE_BAR_COMPONENT_KEY } from '../utilities/utilities';
import ReassignTasks from '../views/ReassignTasks';

function useSideBarComponent(compKey) {
	const sidebarComponents = [
		{ key: ACTION_SIDE_BAR_COMPONENT_KEY.REASSIGN, component: <ReassignTasks /> },
	];
	return sidebarComponents?.find((comp) => comp?.key == compKey)?.component || null;
}

export default useSideBarComponent;
