import { useEffect, useMemo, useState } from 'react';
import Sidebar from '../components/sidebar/SideBar';
import { Input } from '../components/Inputs/Input';
import Filter from '../components/sidebar/Filter';
import { useHistory } from 'react-router';
import * as WorkSpaceActions from '../redux/actions/dashboard/workspaces-actions';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../components/sidebar/SectionHeader';
import InvocieTopBar from '../components/Invoices/InvocieTopBar';
import {
	TableItemWidth1,
	TableItemWidth3,
	TableItemWidth4,
} from '../components/TableSpacing/TableSpacingStyles';
import TableHeader from '../components/Others/TableHeader';
import TableHeaderItem from '../components/Others/TableHeaderItem';
import TableRowItem from '../components/Others/TableRowItem';
import TableRowButton from '../components/Others/TableRowButton';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../assets/icons/Calendarcolor.svg';
import { ReactComponent as InvocieIcon } from '../assets/icons/Invoice.svg';
import * as InvoicesAction from '../redux/actions/invoices-actions';
import * as clientActions from '../redux/actions/client-actions';
import * as AccountingActions from '../redux/actions/integrations/accounting-actions';

import moment from 'moment';
import {
	dateRanges,
	getCapatalizeString,
	invoices_status,
	numberWithCommas,
	type_options,
} from '../utilities/utilities';
import TableRow from '../components/Others/TableRow';
import ActionButton from '../components/Buttons/ActionButton';
import { CircularProgress, Modal } from '@mui/material';
import useModal from '../components/Modal/useModal';
import DummyModal from '../components/Modal/DummyModal';
import SelectCustomerModal from '../components/Modal/SelectCustomerModal';
import DropDown from '../components/Inputs/DropDown';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getUserSettings } from '../hooks/getUserSettings';

function Invoices() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { invoices, filters } = useSelector((state) => state.invoicesReducer);
	const integrations = useSelector((state) => state.AccountingReducer.integration);
	const clients = useSelector((state) => state.clientReducer.clients);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [invoiceId, setInvoiceId] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
    const [dateRange, setDateRange] = useState(null);
	const { modalIsOpen, toggleModal } = useModal();
	const { date_format } = getUserSettings(userDetails);

	const open = Boolean(anchorEl);

	const handleClickGenerate = () => {
		history.push(`/customize-invoice`);
	};

	const resetFirst = () => {
		setSearch('');
	};

	const changeHandler = (event) => {
		setSearch(event.target.value);
	};

	const handleDateChange = (event) => {
		const { value } = event.target;

		const selectedRange = dateRanges?.find((range) => range.label === value);
		console.log(selectedRange, 'selected123', event.target);
		if (selectedRange) {
			dispatch(InvoicesAction.handleInvoiceDateFilter(selectedRange?.value));
            setDateRange(selectedRange?.label);
		}
	};

	const statusFilterOptions = useMemo(() => {
		return invoices_status.map((item) => ({
			value: item.value,
			label: item.label,
			is_checked: filters.status.includes(item.value),
		}));
	}, [filters]);

	const typeFilterOptions = useMemo(() => {
		return type_options.map((item) => ({
			value: item.value,
			label: item.label,
			is_checked: filters.type.includes(item.value),
		}));
	}, [filters]);

	const filteredInvoices = invoices?.filter((item) => {
		const client = clients?.find((client) => client.id === item.client_id);
		if (!client) return false;

		const clientName = client?.company_name || '';
		return clientName.toLowerCase().includes(search.toLowerCase());
	});

	const handleDeleteInvoice = (id) => {
		setLoading(true);
		dispatch(InvoicesAction.deleteInvoice(id))
			.then(() =>
				dispatch(
					WorkSpaceActions.setSnackBarIsOpen(true, true, 'Invoice deleted successfully!'),
				),
			)
			.catch(() => {
				dispatch(
					WorkSpaceActions.setSnackBarIsOpen(false, true, 'Failed to delete invoice.'),
				);
			})
			.finally(() => {
				dispatch(InvoicesAction.getInvoices(filters)).finally(() => setLoading(false));
			});
	};

	useEffect(() => {
		dispatch(InvoicesAction.getInvoices(filters));
		dispatch(AccountingActions.getIntegration());
		dispatch(clientActions.getClients());
	}, [dispatch, filters]);

	return (
		<div className='AppBody'>
			<Sidebar title='Invoices'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<div className='invoice-client-search-bar'>
							<SectionHeader title='Clients' label='Clear All' onClear={resetFirst} />
							<Input
								placeholder='Enter Client Name'
								name='first'
								value={search}
								onChange={changeHandler}
								style={{ color: 'white' }}
							/>
						</div>
						<div className='SidebarListWrapper'>
							<Filter
								title='Type'
								options={typeFilterOptions}
								onClear={() => dispatch(InvoicesAction.handleClearTypeCheckbox())}
								onChange={(e) =>
									dispatch(InvoicesAction.handleTypeCheckbox(e.target.value))
								}
							/>
						</div>
						<div className='improved-input-designs pb-2 mt-1'>
							<SectionHeader
								title='Invoice Date'
								label='Clear All'
								onClear={() => {
                                    setDateRange(null);
                                    dispatch(InvoicesAction.handleClearDate());
                                }}
							/>
							<div className='improved-datePicker-design'>
								<DropDown
									options={dateRanges?.map((range) => ({
										label: range.label,
										value: range.label,
									}))}
                                    value={{value: dateRange}}
									placeholder='Choose Date'
									onChange={handleDateChange}
									style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
									selectClassName='custom-bg'
								/>
							</div>
						</div>
						<div className='SidebarListWrapper'>
							<Filter
								title='Status'
								options={statusFilterOptions}
								onClear={() => dispatch(InvoicesAction.handleClearStatusCheckbox())}
								onChange={(e) =>
									dispatch(InvoicesAction.handleStatusCheckbox(e.target.value))
								}
							/>
						</div>
					</div>
				</div>
			</Sidebar>

			<div className='zapier-main zapierSection-main time-reports-main d-flex flex-column'>
				<InvocieTopBar
					save={true}
					Title='Invoices'
					btnText='Integrations'
					textTransparent={true}
					btnText2='Generate Invoice'
					GenerateOnClick={handleClickGenerate}
					onSave={() => history.push('/integration')}
					restrictBtn={true}
				/>
				<div className='d-flex flex-column w-100 align-items-center' style={{ gap: '1em' }}>
					<div
						className='text-left w-100 d-flex align-items-center justify-content-between information-header p-3'
						style={{ backgroundColor: 'white' }}
					>
						<span className='d-flex' style={{ gap: '12px' }}>
							<div style={{ height: '24px', width: '24px' }}>
								<InvocieIcon />
							</div>
							<h3 className='HeadlineThreeBold'>Invoice Log</h3>
						</span>
					</div>
				</div>

				<div className='Profile-Content'>
					<div
						className='d-flex row w-100 justify-content-between information-content'
						style={{
							borderTop: '1px solid  #EAEAEA ',
							padding: '1em',
							gap: '2%',
							background: '#EEF2F3',
							width: '100%',
							margin: 'auto',
							boxShadow: '0px 8px 24px 0px #959DA533',
						}}
					>
						{filteredInvoices?.length ? (
							<>
								<TableHeader
									ClassName='TableHeader flex invocie-workFlow-table'
									border={false}
								>
									<TableHeaderItem
										ClassName='UManagement'
										label='Invoice no'
										width={TableItemWidth4}
										margin='ml-5'
									></TableHeaderItem>

									<TableHeaderItem
										ClassName='UManagement'
										label='Client Name'
										width={TableItemWidth4}
									/>

									<TableHeaderItem
										ClassName='UManagement'
										label='Type'
										width={TableItemWidth3}
									/>

									<TableHeaderItem
										ClassName={'UManagement'}
										label='Invoice Date'
										width={TableItemWidth3}
									/>
									<TableHeaderItem
										ClassName='UManagement'
										label='Amount'
										width={TableItemWidth3}
									/>
									<TableHeaderItem
										ClassName='UManagement '
										label='Status'
										width={TableItemWidth3}
									/>
									<TableHeaderItem
										ClassName='UManagement '
										label='Sync Status'
										width={TableItemWidth3}
									/>
									<TableHeaderItem
										ClassName='UManagement '
										label='Action'
										width={TableItemWidth1}
										margin='mb-0'
									/>
								</TableHeader>

								<div
									data-cy='all-invoices-list'
									className='ListingLoading listing-scroll'
								>
									{loading ? (
										<CircularProgress
											size={22}
											sx={{ color: '#FB7A03', marginTop: '20px' }}
											disableShrink
										/>
									) : (
										filteredInvoices?.map((item) => (
											<div
												// data-cy={
												// 	user.user_id === loggedInUser.userId
												// 		? ''
												// 		: 'user'
												// }
												className='TableRow d-flex align-items-center h-auto'
												key={item._id}
											>
												<TableRowButton
													ClassName='UManagement text-left'
													width={TableItemWidth4}
													margin='ml-5'
													label={item.invoice_no + '-' + item.invoice_seq}
													clickHandler={() => {
														history.push(`/invoices/${item?._id}`);
													}}
												/>
												<TableRowItem
													width={TableItemWidth4}
													ClassName='UManagement text-left'
													label={
														clients?.find(
															(client) =>
																client?.id === item.client_id,
														)?.dba ||
														clients?.find(
															(client) =>
																client?.id === item.client_id,
														)?.company_name
													}
												/>
												<TableRowItem
													width={TableItemWidth3}
													ClassName='UManagement text-left'
													label={getCapatalizeString(item.type)}
												/>
												<TableRowItem
													width={TableItemWidth3}
													ClassName='UManagement text-left'
													label={moment.utc(item.issue_date).format(
														date_format,
													)}
												/>
												<TableRowItem
													ClassName='UManagement'
													text='text-left'
													width={TableItemWidth3}
													label={`${getSymbolFromCurrency(
														item.currency,
													)}${numberWithCommas(item.total_amount) || 0}`}
												/>
												<TableRowItem
													ClassName='UManagement text-left'
													width={TableItemWidth3}
													label={item.status}
												/>
												<TableRowItem
													ClassName='UManagement text-left'
													width={TableItemWidth3}
													label={
														integrations != null
															? getCapatalizeString(item.sync_status)
															: '-'
													}
												/>
												<TableRowButton
													ClassName='UManagement'
													text='text-left'
													width={TableItemWidth1}
												>
													{item?.status != 'Void' && (
														<ActionButton
															isOpen={open}
															AnchorEl={anchorEl}
															EditUser={() => {
																history.push(
																	`/invoices/${item?._id}`,
																);
															}}
															HandleRemove={() =>
																handleDeleteInvoice(item?._id)
															}
															customAction={
																item?.status === 'Draft' ||
																item?.sync_status === 'success'
																	? false
																	: true
															}
															customText='Select Customer'
															onCustomClick={() => {
																setInvoiceId(item?._id);
																toggleModal();
															}}
															btnText={
																item.status === 'Draft'
																	? 'Delete'
																	: 'Void'
															}
															removebtn
														/>
													)}
												</TableRowButton>
											</div>
										))
									)}
								</div>
							</>
						) : (
							<div
								className='d-flex w-100 justify-content-between information-content'
								style={{
									padding: '1em',
									gap: '2%',
									marginTop: '10px',
									background: 'white',
									width: '100%',
								}}
							>
								<h3 className='BodyTwoLight mb-0 table-header-text  ml-1 m-auto opacity-75'>
									No invoices currently. Click{' '}
									<span
										style={{ color: '#FB7A03' }}
										className='pointer'
										onClick={handleClickGenerate}
									>
										here{' '}
									</span>
									to create a new invoice
								</h3>
							</div>
						)}
					</div>
				</div>
			</div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<DummyModal
							sectionhead
							hideCrossIcon
							onClose={toggleModal}
							style={{ width: '50%', maxWidth: '686px' }}
						>
							<SelectCustomerModal
								onCancel={toggleModal}
								options={customers}
								invoiceId={invoiceId}
								setCustomers={setCustomers}
							/>
						</DummyModal>
					</>
				</Modal>
			)}
		</div>
	);
}

export default Invoices;
