/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment-timezone';
import { setWorkflowTasksOrder, toLocalTz } from '../../../utilities/utilities';
import * as Actions from '../../actions/dashboard/workspaces-actions';

const initialState = {
	tasks: null,
	taskTypes: [],
	assignees: [],
	clients: [],
	clientUsers: [],
	weeklyTasks: null,
	weeklyClientTasks: null,
	weeklyMeetingTasks: null,
	// taskDetails: null,
	specificTaskID: null,
	createTaskForm: null,
	repeatTime: null,
	loading: false,
	updateCardLoading: false,
	errors: null,
	cardAdded: false,
	comment: '',
	taskUpdateError: null,
	filteredWorkSpace: null,
	filteredWorkflows: null,
	socketConnection: false,
	workspaceTeams: null,
	activeUsers: [],
	getSpecificTaskError: false,
	unassignedClientTasks: [],
	openActionCenterModal: false,
	workflowName: '',
	recentlyAddedTask: {},
	filters: localStorage.getItem('workspace_applied_filters')
		? JSON.parse(localStorage.getItem('workspace_applied_filters'))
		: {
				clients: [],
				users: [],
				taskStatuses: [],
				teams: [],
				workflows: [],
		  },
	snackBar: {
		IsOpen: false,
		message: '',
	},
	buttonLoading: false,
	taskTimerData: {},

	taskDragError: null,
	triggerGetTasks: false,
	getMeetings: false,
	toggleTeamMeetings: false,
	timeTrackingError: null,
	monthlyView: localStorage.getItem('monthly')
		? JSON.parse(localStorage.getItem('monthly'))
		: false,
	weekDaysOff: localStorage.getItem('weekend')
		? JSON.parse(localStorage.getItem('weekend'))
		: false,
	weekDate: undefined,
	monthlyDate: undefined,
	meetingTaskLoading: false,
	clientTaskLoading: false,
	newTaskDetails: null,
	clinet_software_stacks: [],
	taskAssignees: [],
	workflowSubmission: null,
	taskComments: [],
	taskLoading: false,
	eventSource: undefined,
	resourceLoading: false,
	activeUser: {},
	showTeamMembersMeetings: false,
	weeklyClientMeetingTasks: null,
	isTaskMinimized: false,
	taskClicked: {
		taskId: null,
		modalType: '',
	},
	isTimeTracking: false,
	bulkClientTasks: [],
};

const WorkSpaceReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.SET_SNACKBAR_IS_OPEN:
			return (state = {
				...state,
				snackBar: payload,
			});
		case Actions.SET_LOADING:
			return (state = {
				...state,
				loading: payload,
			});
		case Actions.SET_RESOURCE_LOADING:
			return (state = {
				...state,
				resourceLoading: payload,
			});
		case Actions.SET_MEETING_TASKS_LOADING:
			return (state = {
				...state,
				meetingTaskLoading: payload,
			});
		case Actions.SET_CLIENT_TASKS_LOADING:
			return (state = {
				...state,
				clientTaskLoading: payload,
			});
		case Actions.GET_TASKS:
			return (state = {
				...state,
				tasks: payload,
				loading: false,
				// cardAdded: true,
			});
		case Actions.SET_TASK_DETAILS:
			return (state = {
				...state,
				newTaskDetails: payload,
			});
		case Actions.SET_CLIENT_SOFTWARE_STACKS:
			return (state = {
				...state,
				clinet_software_stacks: payload,
			});
		case Actions.SET_TASK_ASSIGNEES:
			return (state = {
				...state,
				taskAssignees:
					state.newTaskDetails && payload.length
						? state.newTaskDetails.id == payload[0].task_id
							? payload
							: state.taskAssignees
						: state.taskAssignees,
			});
		case Actions.RESET_TASK_ASSIGNEES:
			return (state = {
				...state,
				taskAssignees: payload,
			});
		case Actions.REMOVE_TASK_ASSIGNEE:
			return (state = {
				...state,
				taskAssignees: state.newTaskDetails
					? state.newTaskDetails.id == payload.task_id
						? []
						: state.taskAssignees
					: state.taskAssignees,
			});
		case Actions.SET_WORKFLOW_SUBMISSION:
			return (state = {
				...state,
				workflowSubmission: payload[0]?.data[0],
			});
		case Actions.SET_TASK_COMMENTS:
			return (state = {
				...state,
				taskComments: payload,
			});
		case Actions.UPDATE_TASK_COMMENTS:
			return (state = {
				...state,
				taskComments: [...state.taskComments, payload],
			});
		case Actions.UPDATE_TASK_INFO:
			var taskInfoOject = payload[0];
			var newTempTask = state?.newTaskDetails ? { ...state.newTaskDetails } : null;
			if (taskInfoOject?.id == state?.newTaskDetails?.id) {
				newTempTask = {
					...newTempTask,
					...taskInfoOject,
					assignee_users: taskInfoOject?.task_assignees,
				};
			}
			return (state = {
				...state,
				newTaskDetails: newTempTask,
			});
		case Actions.UPDATE_WEEKLY_CLIENT_TASK_ASSIGNEE:
			return (state = {
				...state,
				weeklyClientTasks:
					state?.weeklyClientTasks?.length > 0
						? state.weeklyClientTasks?.map((client) => {
								if (client?.client_id == payload[0].task?.client_id) {
									return {
										...client,
										tasks: client?.tasks?.map((task) => {
											if (task?.id == payload[0].task_id) {
												return {
													...task,
													task_assignees:
														task?.task_assignees?.length > 0
															? task?.task_assignees?.map(
																	(assignee) => {
																		if (
																			assignee?.user_profile
																				?.id !==
																			payload[0]?.user_profile
																				?.id
																		) {
																			return {
																				...assignee,
																				user_profile:
																					payload[0]
																						?.user_profile,
																			};
																		} else {
																			return assignee;
																		}
																	},
															  )
															: payload,
												};
											} else return task;
										}),
									};
								} else return client;
						  })
						: state?.weeklyClientTasks,
			});
		case Actions.REMOVE_WEEKLY_CLIENT_TASK_ASSIGNEE:
			return (state = {
				...state,
				weeklyClientTasks:
					state.weeklyClientTasks?.length > 0 &&
					state.weeklyClientTasks?.map((client) => {
						if (client?.client_id == payload?.task?.client_id) {
							return {
								...client,
								tasks: client?.tasks?.map((task) => {
									if (task?.id == payload.task_id) {
										return {
											...task,
											task_assignees: [],
										};
									} else return task;
								}),
							};
						} else return client;
					}),
			});
		case Actions.SET_TASK_DETAILS_LOADING:
			return (state = {
				...state,
				taskLoading: payload,
			});
		case Actions.UPDATE_MONTHLY_TASK:
			var monthlyTaskIndex = state.tasks.findIndex((a) => a.id === payload.id);

			var monthlyTaskArr = [...state.tasks];

			monthlyTaskArr[monthlyTaskIndex] = payload;

			return (state = {
				...state,
				tasks: [...monthlyTaskArr],
			});
		case Actions.GET_TASK_TYPE:
			return (state = {
				...state,
				taskTypes: payload,
			});
		case Actions.GET_ASSIGNEES:
			return (state = {
				...state,
				assignees: payload,
			});
		case Actions.GET_CLIENT_USERS:
			return (state = {
				...state,
				clientUsers: payload,
			});
		case Actions.HANDLE_CHANGE:
			if (
				payload.name === 'title' ||
				payload.name === 'description' ||
				payload.name === 'repeat_type' ||
				payload.name === 'is_approval_required' ||
				payload.name === 'approval_from' ||
				payload.name === 'budgeted_hours' ||
				payload.name == 'start_time' ||
				payload.name == 'end_time' ||
				payload.name == 'notes' ||
				payload.name == 'agenda' ||
				payload.name == 'meeting_guests' ||
				payload.name == 'meeting_platform' ||
				payload.name == 'meeting_link' ||
				payload.name == 'color' ||
				payload.name == 'is_monthly_on_day' ||
				payload.name == 'recur_preference' ||
				payload.name == 'repeat_interval' ||
				payload.name == 'required_days'
			) {
				return (state = {
					...state,
					createTaskForm: {
						...state.createTaskForm,
						[payload.name]: payload.value,
					},
				});
			} else {
				return (state = {
					...state,
					createTaskForm: {
						...state.createTaskForm,
						[payload.name]: JSON.parse(payload.value),
					},
				});
			}

		case Actions.SET_OPEN_ACTION_CENTER_MODAL:
			return (state = {
				...state,
				openActionCenterModal: payload,
			});
		case Actions.SET_WORKFLOW_NAME:
			return (state = {
				...state,
				workflowName: payload,
			});

		case Actions.RESET_WORKFLOW_NAME:
			return (state = {
				...state,
				workflowName: '',
			});
		case Actions.HANDLE_REPEAT_CHANGE:
			return (state = {
				...state,
				repeatTime: payload,
			});

		case Actions.HANDLE_DATE_CHANGE:
			return (state = {
				...state,
				createTaskForm: {
					...state.createTaskForm,
					due_date: payload,
				},
			});

		case Actions.HANDLE_COMMENT_CHANGE:
			return (state = {
				...state,
				comment: payload,
			});
		case Actions.RESET_COMMENT:
			return (state = {
				...state,
				comment: '',
			});
		case Actions.POST_COMMENT:
			return (state = {
				...state,
				taskComments: state.newTaskDetails
					? state.newTaskDetails.id == payload.task_id
						? [...state.taskComments, payload]
						: state.taskComments
					: state.taskComments,
			});
		case Actions.ADD_COMMENT:
			return (state = {
				...state,
				taskComments: state.taskComments.filter((comment) => !comment.comment_id),
			});
		case Actions.UPDATE_COMMENTS_ATTACHMENTS: {
			const updatedTaskComments = state.taskComments?.map((comment) => {
				if (comment?.attachment) {
					const filteredAttachments = comment?.attachment?.filter((attachment) => {
						return attachment?.document_id !== payload.id;
					});
					return {
						...comment,
						attachment: filteredAttachments,
					};
				}
				return comment;
			});
			return {
				...state,
				taskComments: updatedTaskComments,
			};
		}

		case Actions.CREATE_TASK_END:
			return (state = {
				...state,
				recentlyAddedTask: payload,
			});
		case Actions.CREATE_TASK_FAILED:
			return (state = {
				...state,
				errors: payload,
			});
		case Actions.GET_UNASSIGNED_CLIENT_TASKS:
			return (state = {
				...state,
				unassignedClientTasks: payload,
			});

		// case Actions.GET_WEEKLY_TASKS: {
		// 	const client_tasks = payload?.clients?.map((client) => {
		// 		const unique_recurrence_workflows = new Array();
		// 		client?.tasks?.reverse()?.map((task) => {
		// 			if (!unique_recurrence_workflows?.includes(task?.workflow_recurrence_id)) {
		// 				unique_recurrence_workflows.push(task?.workflow_recurrence_id);
		// 			}
		// 		});

		// 		const sorted_tasks = [];
		// 		unique_recurrence_workflows?.map((workflow_recurrence_id) => {
		// 			const client_workflow_tasks = client?.tasks?.filter(
		// 				(task) =>
		// 					task?.workflow_recurrence_id &&
		// 					workflow_recurrence_id == task?.workflow_recurrence_id,
		// 			);
		// 			const sort_filter_tasks = setWorkflowTasksOrder(client_workflow_tasks);
		// 			sorted_tasks.push(...sort_filter_tasks);
		// 		});
		// 		sorted_tasks?.push(
		// 			...client?.tasks?.filter((task) => task?.workflow_recurrence_id == null),
		// 		);

		// 		return {
		// 			...client,
		// 			tasks: sorted_tasks,
		// 		};
		// 	});

		// 	return (state = {
		// 		...state,
		// 		weeklyTasks: [
		// 			{
		// 				tasks: payload.my_tasks,
		// 			},
		// 			...client_tasks,
		// 		],
		// 	});
		// }

		case Actions.SET_WEEKLY_MEETING_TASKS: {
			const my_tasks = payload?.map((mTask) => {
				const startDateTime = moment.utc(
					mTask?.task_start_date + ' ' + mTask.task_start_time,
					'YYYY-MM-DD HH:mm:ss',
				);
				const endDateTime = moment.utc(
					mTask?.task_start_date + ' ' + mTask.task_end_time,
					'YYYY-MM-DD HH:mm:ss',
				);

				const localeStartDateTime = toLocalTz(startDateTime);
				const localeEndDateTime = toLocalTz(endDateTime);

				return {
					...mTask,
					task_start_date: moment(localeStartDateTime).format('YYYY-MM-DD'),
					task_start_time: moment(localeStartDateTime),
					task_end_time: moment(localeEndDateTime),
				};
			});

			return (state = {
				...state,
				weeklyMeetingTasks: my_tasks,
				weeklyClientMeetingTasks: null,
			});
		}

		case Actions.SET_TEAM_MEMBERS_MEETINGS: {
			const withoutClientTasks = payload?.withoutClientMeeting?.map((mTask) => {
				const startDateTime = moment.utc(
					mTask?.task_start_date + ' ' + mTask.task_start_time,
					'YYYY-MM-DD HH:mm:ss',
				);
				const endDateTime = moment.utc(
					mTask?.task_start_date + ' ' + mTask.task_end_time,
					'YYYY-MM-DD HH:mm:ss',
				);

				const localeStartDateTime = toLocalTz(startDateTime);
				const localeEndDateTime = toLocalTz(endDateTime);

				return {
					...mTask,
					task_start_date: moment(localeStartDateTime).format('YYYY-MM-DD'),
					task_start_time: moment(localeStartDateTime),
					task_end_time: moment(localeEndDateTime),
				};
			});
			const withClientTasks = payload?.withClientMeeting?.map((client) => {
				return {
					...client,
					tasks: client?.tasks?.map((task) => {
						const startDateTime = moment.utc(
							task?.start_date + ' ' + task.start_time,
							'YYYY-MM-DD HH:mm:ss',
						);
						const endDateTime = moment.utc(
							task?.start_date + ' ' + task.end_time,
							'YYYY-MM-DD HH:mm:ss',
						);

						const localeStartDateTime = toLocalTz(startDateTime);
						const localeEndDateTime = toLocalTz(endDateTime);
						return {
							...task,
							start_date: moment(localeStartDateTime).format('YYYY-MM-DD'),
							start_time: moment(localeStartDateTime),
							end_time: moment(localeEndDateTime),
						};
					}),
				};
			});
			return (state = {
				...state,
				weeklyMeetingTasks: withoutClientTasks,
				weeklyClientMeetingTasks: withClientTasks,
			});
		}

		case Actions.SET_WEEKLY_CLIENT_TASKS: {
			const client_tasks = payload?.map((client) => {
				const unique_recurrence_workflows = new Array();
				client?.tasks?.reverse()?.map((task) => {
					if (!unique_recurrence_workflows?.includes(task?.workflow_recurrence_id)) {
						unique_recurrence_workflows.push(task?.workflow_recurrence_id);
					}
				});

				const sorted_tasks = [];
				unique_recurrence_workflows?.map((workflow_recurrence_id) => {
					const client_workflow_tasks = client?.tasks?.filter(
						(task) =>
							task?.workflow_recurrence_id &&
							workflow_recurrence_id == task?.workflow_recurrence_id,
					);
					const sort_filter_tasks = setWorkflowTasksOrder(client_workflow_tasks);
					sorted_tasks.push(...sort_filter_tasks);
				});
				sorted_tasks?.push(
					...client?.tasks?.filter((task) => task?.workflow_recurrence_id == null),
				);

				return {
					...client,
					tasks: sorted_tasks,
				};
			});

			return (state = {
				...state,
				weeklyClientTasks: [...client_tasks],
			});
		}
		case Actions.UPDATE_WEEKLY_CLIENT_TASKS:
			return (state = {
				...state,
				weeklyClientTasks: payload,
			});
		case Actions.FILTER_TASK_FROM_WEEKLY_ARRAY:
			return (state = {
				...state,
				weeklyClientTasks:
					state.weeklyClientTasks?.length > 0 &&
					state.weeklyClientTasks.map((client) => {
						if (client?.client_id == payload?.client_id) {
							return {
								...client,
								tasks: client?.tasks?.filter((task) => task?.id !== payload.id),
							};
						} else return client;
					}),
			});
		case Actions.FILTER_MEETING_TASK_FROM_WEEKLY_ARRAY: {
			let tempMeetingTasks = state.weeklyMeetingTasks && [...state.weeklyMeetingTasks];
			let tempClientMeetingTasks = state.weeklyClientMeetingTasks && [
				...state.weeklyClientMeetingTasks,
			];
			if (state.showTeamMembersMeetings) {
				if (payload?.client_id) {
					tempClientMeetingTasks = tempClientMeetingTasks.map((client) => {
						if (client?.client_id == payload?.client_id) {
							return {
								...client,
								tasks: client?.tasks?.filter((task) => task?.id !== payload.id),
							};
						} else return client;
					});
				} else {
					tempMeetingTasks = tempMeetingTasks?.filter(
						(task) => task?.task_id !== payload?.id,
					);
				}
			} else {
				tempMeetingTasks = tempMeetingTasks?.filter(
					(task) => task?.task_id !== payload?.id,
				);
			}
			return (state = {
				...state,
				weeklyMeetingTasks: tempMeetingTasks,
				weeklyClientMeetingTasks: tempClientMeetingTasks,
			});
		}

		case Actions.RESET_WORKSPACE_TASKS:
			return (state = {
				...state,
				weeklyClientTasks: payload,
				weeklyMeetingTasks: payload,
				weeklyClientMeetingTasks: payload,
			});

		case Actions.UPDATE_TASK_CARD: {
			var { task, index, dragOverContainer, weeklyClientTasks } = payload;
			if (task.task_type_id === 1) {
				task = {
					...task,
					due_date: dragOverContainer,
				};
			} else {
				task = {
					...task,
					extended_date: dragOverContainer,
				};
			}
			const clientTaskIndex = payload?.weeklyClientTasks[payload?.index]?.tasks?.findIndex(
				(t) => t.id === payload?.task?.id,
			);

			var tempArr = [...weeklyClientTasks];
			if (tempArr[index] && tempArr[index]?.tasks) {
				tempArr[index].tasks[clientTaskIndex] = task;
			}
			// tempArr[index]?.tasks?.[clientTaskIndex] = task;

			return (state = {
				...state,
				weeklyClientTasks: [...tempArr],
			});
		}

		case Actions.RESET_ERROR: {
			return (state = {
				...state,
				taskUpdateError: null,
			});
		}
		case Actions.SET_SPECIFIC_TASK:
			return (state = {
				...state,
				specificTaskID: payload,
			});
		case Actions.UPDATE_WEEKLY_TASK:
			if (payload?.client_id || payload?.client_workflow_task_id) {
				var clientIndex = state.weeklyClientTasks.findIndex(
					(client) => client.client_id === payload?.client_id,
				);

				var taskIndex = state.weeklyClientTasks[clientIndex]?.tasks?.findIndex(
					(task) => task.id === payload?.id,
				);

				var tempWeeklyArr = [...state.weeklyClientTasks];
				if (tempWeeklyArr[clientIndex] && tempWeeklyArr[clientIndex]?.tasks) {
					tempWeeklyArr[clientIndex].tasks[taskIndex] = payload;
				}
			}
			return (state = {
				...state,
				weeklyClientTasks: [...tempWeeklyArr],
			});
		case Actions.UPDATE_TASK_START:
			return (state = {
				...state,
				updateCardLoading: true,
			});
		case Actions.SET_TASK_LOADING:
			return (state = {
				...state,
				updateCardLoading: payload,
			});
		case Actions.UPDATE_MEETING_TASKS: {
			const taskObject = payload[0];
			const tempWeeklyMeetingTasks = state.weeklyMeetingTasks && [
				...state.weeklyMeetingTasks,
			];
			let tempWeeklyClientMeetingTasks = state.weeklyClientMeetingTasks && [
				...state.weeklyClientMeetingTasks,
			];
			const MeetingTaskStateManager = () => {
				const taskIndex = state.weeklyMeetingTasks.findIndex(
					(task) => task?.task_id == taskObject?.id,
				);
				const task_date = moment.utc(taskObject?.start_date).format('YYYY-MM-DD');
				const startDateTime = moment.utc(
					task_date + ' ' + taskObject.start_time,
					'YYYY-MM-DD HH:mm:ss',
				);
				const endDateTime = moment.utc(
					task_date + ' ' + taskObject.end_time,
					'YYYY-MM-DD HH:mm:ss',
				);

				const localeStartDateTime = toLocalTz(startDateTime);
				const localeEndDateTime = toLocalTz(endDateTime);
				const meetingDetails = state.weeklyMeetingTasks[taskIndex];
				tempWeeklyMeetingTasks[taskIndex] = {
					...meetingDetails,
					task_due_date: taskObject?.due_date,
					task_end_time: moment(localeEndDateTime),
					task_extended_date: taskObject?.extended_date,
					task_start_date: moment(localeStartDateTime).format('YYYY-MM-DD'),
					task_start_time: moment(localeStartDateTime),
					task_title: taskObject?.title,
					task_color: taskObject?.metadata?.color,
					task_assignees: taskObject?.task_assignees,
				};
			};
			if (state.showTeamMembersMeetings) {
				if (taskObject?.client_id) {
					tempWeeklyClientMeetingTasks = tempWeeklyClientMeetingTasks?.map((client) => {
						if (client?.client_id == taskObject?.client_id) {
							return {
								...client,
								tasks: client?.tasks?.map((task) => {
									if (task?.id == taskObject?.id) {
										const startDateTime = moment.utc(
											taskObject?.start_date + ' ' + taskObject.start_time,
											'YYYY-MM-DD HH:mm:ss',
										);
										const endDateTime = moment.utc(
											taskObject?.start_date + ' ' + taskObject.end_time,
											'YYYY-MM-DD HH:mm:ss',
										);

										const localeStartDateTime = toLocalTz(startDateTime);
										const localeEndDateTime = toLocalTz(endDateTime);
										return {
											...taskObject,
											task_assignees: taskObject?.task_assignees,
											start_date:
												moment(localeStartDateTime).format('YYYY-MM-DD'),
											start_time: moment(localeStartDateTime),
											end_time: moment(localeEndDateTime),
										};
									} else return task;
								}),
							};
						} else return client;
					});
				} else if (tempWeeklyMeetingTasks) {
					MeetingTaskStateManager();
				}
			} else if (tempWeeklyMeetingTasks) {
				MeetingTaskStateManager();
			}
			return (state = {
				...state,
				weeklyMeetingTasks: tempWeeklyMeetingTasks,
				weeklyClientMeetingTasks: tempWeeklyClientMeetingTasks,
			});
		}
		case Actions.UPDATE_WEEKLY_TASK_INFO:
			var taskObject = payload[0];
			var tempUpdateWeeklyArr = state.weeklyClientTasks ? [...state.weeklyClientTasks] : [];

			if (tempUpdateWeeklyArr?.length > 0) {
				if (taskObject.client_id || taskObject.client_workflow_task_id) {
					var dependetTasks = payload?.filter((task) => task?.id !== taskObject?.id);
					var clientUpdateIndex = state.weeklyClientTasks.findIndex(
						(client) => client.client_id === taskObject.client_id,
					);

					var taskUpdateIndex = state.weeklyClientTasks[
						clientUpdateIndex
					]?.tasks.findIndex((task) => task.id === taskObject.id);

					var TaskDetails =
						tempUpdateWeeklyArr[clientUpdateIndex]?.tasks[taskUpdateIndex];
					if (dependetTasks?.length > 0) {
						tempUpdateWeeklyArr = tempUpdateWeeklyArr?.map((client) => {
							if (client?.client_id == taskObject?.client_id) {
								return {
									...client,
									tasks: client?.tasks?.map((task) => {
										if (
											dependetTasks?.some(
												(dependent_task) => dependent_task?.id == task?.id,
											)
										) {
											var d_task = dependetTasks?.find(
												(d_task) => d_task?.id == task?.id,
											);
											return {
												...task,
												extended_date: d_task?.extended_date,
												start_date: d_task?.start_date,
												due_date: d_task?.due_date,
											};
										} else return task;
									}),
								};
							} else {
								return client;
							}
						});
					}

					if (
						tempUpdateWeeklyArr[clientUpdateIndex] &&
						tempUpdateWeeklyArr[clientUpdateIndex]?.tasks
					) {
						tempUpdateWeeklyArr[clientUpdateIndex].tasks[taskUpdateIndex] = {
							...TaskDetails,
							...taskObject,
						};
					}
				}
			}

			return (state = {
				...state,
				weeklyClientTasks: [...tempUpdateWeeklyArr],
			});
		case Actions.SET_ACTIVE_USERS_LIST:
			return (state = {
				...state,
				activeUsers: payload,
			});
		case Actions.SET_ACTIVE_USER:
			return (state = {
				...state,
				activeUser: payload,
			});
		case Actions.SET_TASK_STATUS:
			return (state = {
				...state,
				weeklyClientTasks:
					state?.weeklyClientTasks?.length > 0
						? state.weeklyClientTasks?.map((client) => {
								if (client?.client_id == payload?.client_id) {
									return {
										...client,
										tasks: client?.tasks?.map((task) => {
											if (task?.id == payload?.id) {
												return {
													...task,
													status: payload?.status,
												};
											} else return task;
										}),
									};
								} else return client;
						  })
						: state.weeklyClientTasks,
				newTaskDetails: state?.newTaskDetails
					? state.newTaskDetails?.id === payload?.id
						? {
								...state.newTaskDetails,
								status: payload.status,
						  }
						: state.newTaskDetails
					: state.newTaskDetails,
			});
		case Actions.GET_SPECIFIC_TASK_ERROR:
			return (state = {
				...state,
				getSpecificTaskError: true,
			});
		case Actions.RESET_TASK_DETAILS:
			return (state = {
				...state,
				newTaskDetails: null,
				// activeUsers: [],
			});
		case Actions.GET_WORKSPACE_TEAMS:
			return (state = {
				...state,
				workspaceTeams: payload,
			});
		case Actions.RESET_CREATE_TASK_FORM:
			return (state = {
				...state,
				createTaskForm: null,
				errors: null,
			});
		case Actions.GET_FILTERED_WORKSPACE:
			return (state = {
				...state,
				filteredWorkSpace: {
					clients: payload.clients,
					taskStatuses: payload.task_status,
					users: payload.people,
				},
			});
		case Actions.GET_FILTERED_WORKFLOWS:
			return (state = {
				...state,
				filteredWorkflows: payload,
			});
		case Actions.HANDLE_CLIENTS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					clients: state.filters.clients.includes(payload)
						? state.filters.clients.filter((p) => p !== payload)
						: [...state.filters.clients, payload],
				},
			});
		case Actions.HANDLE_WORKFLOWS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					workflows: state.filters.workflows.includes(payload)
						? state.filters.workflows.filter((p) => p !== payload)
						: [...state.filters.workflows, payload],
				},
			});
		case Actions.HANDLE_USERS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					users: state.filters.users.includes(payload)
						? state.filters.users.filter((p) => p !== payload)
						: [...state.filters.users, payload],
				},
			});
		case Actions.HANDLE_CLEAR_USER_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					users: [],
				},
			});
		case Actions.HANDLE_CLEAR_WORKFLOWS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					workflows: [],
				},
			});
		case Actions.HANDLE_CLEAR_CLIENT_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					clients: [],
				},
			});
		case Actions.HANDLE_TASK_STATUS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					taskStatuses: state.filters.taskStatuses.includes(payload)
						? state.filters.taskStatuses.filter((p) => p !== payload)
						: [...state.filters.taskStatuses, payload],
				},
			});
		case Actions.HANDLE_TEAM_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					teams: state.filters.teams.includes(payload)
						? state.filters.teams.filter((p) => p !== payload)
						: [...state.filters.teams, payload],
				},
			});
		case Actions.HANDLE_CLEAR_TASK_STATUSES_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					taskStatuses: [],
				},
			});
		case Actions.HANDLE_CLEAR_TEAM_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					teams: [],
				},
			});
		case Actions.SET_FILTERS_VALUES:
			return (state = {
				...state,
				filters: payload || {
					clients: [],
					users: [],
					taskStatuses: [],
					teams: [],
					workflows: [],
				},
			});
		case Actions.UPDATE_MY_TASK_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.UPDATE_LOGGED_HOURS_START:
			return (state = {
				...state,
				buttonLoading: true,
			});
		case Actions.UPDATE_LOGGED_HOURS_END:
			return (state = {
				...state,
				buttonLoading: false,
			});
		case Actions.UPDATE_LOGGED_HOURS_FAILED:
			return (state = {
				...state,
				buttonLoading: false,
			});
		case Actions.UPDATE_MY_TASK_INFO:
			var tempUpdateMyTaskArr = [...state.weeklyTasks];
			if (payload.length > 0) {
				if (payload[0].task_type_id === 2) {
					const filteredMyTaskArr = state.weeklyTasks[0].tasks.filter((task) =>
						payload.find((innerTask) => innerTask.group_key !== task.group_key),
					);
					tempUpdateMyTaskArr[0].tasks = [...filteredMyTaskArr, ...payload];
				}
			} else {
				var myTaskIndex = state.weeklyTasks[0].tasks.findIndex(
					(item) => item.id === payload.id,
				);
				tempUpdateMyTaskArr[0].tasks[myTaskIndex] = payload;
			}
			return (state = {
				...state,
				weeklyTasks: [...tempUpdateMyTaskArr],
			});
		case Actions.DELETE_TASK: {
			return (state = {
				...state,
				loading: false,
				weeklyTasks: state?.weeklyTasks?.map((item) => {
					return {
						...item,
						tasks: payload.DeleteFutureTasks
							? item?.tasks?.filter((i_item) => {
									if (i_item.group_key == payload.group_key) {
										return payload.start_date > i_item.start_date;
									}
									return true;
							  })
							: item?.tasks?.filter((i_item) => i_item.id != payload.taskId),
					};
				}),
			});
		}
		case Actions.DELETE_MEETING_TASK: {
			return (state = {
				...state,
				loading: false,
				weeklyTasks: state?.weeklyTasks?.map((item) => {
					return {
						...item,
						tasks: payload.delete_future
							? item?.tasks?.filter((i_item) => {
									if (
										i_item.task_recurrence?.my_task_id ==
										payload.task_recurrence.my_task_id
									) {
										return payload.start_date > i_item.start_date;
									}
									return true;
							  })
							: item?.tasks?.filter((i_item) => i_item.id != payload.taskId),
					};
				}),
			});
		}
		case Actions.UPDATE_MY_TASK_FAILED:
			return (state = {
				...state,
				loading: false,
				errors: payload,
			});

		case Actions.HANDLE_TASK_TIMER:
			// if (payload?.task?.id === state?.newTaskDetails?.id) {
			// 	var tempObj = state.newTaskDetails;
			// 	tempObj = {
			// 		...tempObj,
			// 		status: payload?.task?.status,
			// 	};
			// }
			return (state = {
				...state,
				taskTimerData: payload,
				// newTaskDetails: tempObj,
			});
		case Actions.RESET_TIMER_STATUS:
			return (state = {
				...state,
				taskTimerData: {},
			});
		case Actions.HANDLE_TIMER_STATUS:
			return (state = {
				...state,
				weeklyClientTasks: payload,
			});
		case Actions.UPDATE_TASK_ERROR:
			return (state = {
				...state,
				taskDragError: payload,
			});
		case Actions.RESET_TASK_DRAG_ERROR:
			return (state = {
				...state,
				taskDragError: payload,
			});
		case Actions.TRIGGER_GET_TASKS:
			return (state = {
				...state,
				triggerGetTasks: !state.triggerGetTasks,
			});
		case Actions.TOGGLE_TEAM_MEETINGS:
			return (state = {
				...state,
				showTeamMembersMeetings: !state.showTeamMembersMeetings,
			});
		case Actions.GET_MEETINGS:
			return (state = {
				...state,
				getMeetings: !state.getMeetings,
			});
		case Actions.SET_MEETING_TASK_RECURRENCE:
			return (state = {
				...state,
				createTaskForm: {
					...state.createTaskForm,
					end_on: payload,
				},
			});
		case Actions.HANDLE_TASK_TYPE:
			return (state = {
				...state,
				createTaskForm: payload,
			});
		case Actions.TIME_TRACKING_ERROR:
			return (state = {
				...state,
				timeTrackingError: payload,
			});
		case Actions.RESET_TIME_TRACKING_ERROR:
			return (state = {
				...state,
				timeTrackingError: payload,
			});
		case Actions.SET_MONTHLY_VIEW:
			return (state = {
				...state,
				monthlyView: payload,
			});
		case Actions.SET_WEEKEND:
			return (state = {
				...state,
				weekDaysOff: payload,
			});
		case Actions.SET_DATES:
			return (state = {
				...state,
				start_date: payload.start_date,
				end_date: payload.end_date,
			});
		case Actions.SET_WEEK_DATE:
			return (state = {
				...state,
				weekDate: payload,
			});
		case Actions.SET_MONTHLY_DATE:
			return (state = {
				...state,
				monthlyDate: payload,
			});
		case Actions.DELETE_CLIENT_AD_HOC_TASKS:
			return (state = {
				...state,
				weeklyTasks: state?.weeklyTasks?.map((client) => ({
					...client,
					tasks: client?.tasks?.filter((task) => task?.id !== payload.id),
				})),
			});
		case Actions.SET_EVENT_SOURCE:
			return (state = {
				...state,
				eventSource: payload,
			});
		case Actions.RESET_STATE:
			return (state = {
				...initialState,
				eventSource: state.eventSource,
			});
		case Actions.SET_MEETING_INVITE_STATUS:
			return (state = {
				...state,
				newTaskDetails: {
					...state.newTaskDetails,
					assignee_users: state.newTaskDetails?.assignee_users?.map((assignee) => {
						if (assignee?.user_id == payload?.user_id) {
							return {
								...assignee,
								status: payload.status,
							};
						} else return assignee;
					}),
				},
			});
		case Actions.SET_TASK_MODAL_MINIMIZE:
			return (state = {
				...state,
				isTaskMinimized: payload,
			});
		case Actions.HANDLE_TASK_CLICK:
			return (state = {
				...state,
				taskClicked: payload,
			});
		case Actions.SET_IS_TIME_TRACKING:
			return (state = {
				...state,
				isTimeTracking: payload,
			});
		case Actions.UPDATE_TASK_TIMER: {
			let taskDetails = state.newTaskDetails ? { ...state.newTaskDetails } : null;
			if (taskDetails) {
				const task_timer = taskDetails?.task_timers?.find(
					(timer) => timer?.id === payload?.id,
				);
				const is_same_task = taskDetails?.id === payload?.task_id;
				const is_task_assignee = taskDetails?.assignee_users?.some(
					(assignee) => assignee?.user_id === payload?.user_id,
				);
				if (is_task_assignee && is_same_task) {
					if (task_timer) {
						taskDetails = {
							...taskDetails,
							task_timers: taskDetails?.task_timers?.map((timer) => {
								if (timer?.id === payload?.id) {
									return {
										...timer,
										consumed_time: payload?.consumed_time,
									};
								} else return timer;
							}),
						};
					} else {
						taskDetails = {
							...taskDetails,
							task_timers: [...taskDetails?.task_timers, payload],
						};
					}
				}
			}

			return (state = {
				...state,
				newTaskDetails: taskDetails,
			});
		}
		case Actions.SET_BULK_CLIENT_TASKS: {
			const client_tasks = payload?.map((client) => {
				const unique_recurrence_workflows = new Array();
				client?.tasks?.reverse()?.map((task) => {
					if (!unique_recurrence_workflows?.includes(task?.workflow_recurrence_id)) {
						unique_recurrence_workflows.push(task?.workflow_recurrence_id);
					}
				});

				const sorted_tasks = [];
				unique_recurrence_workflows?.map((workflow_recurrence_id) => {
					const client_workflow_tasks = client?.tasks?.filter(
						(task) =>
							task?.workflow_recurrence_id &&
							workflow_recurrence_id == task?.workflow_recurrence_id,
					);
					const sort_filter_tasks = setWorkflowTasksOrder(client_workflow_tasks);
					sorted_tasks.push(...sort_filter_tasks);
				});
				sorted_tasks?.push(
					...client?.tasks?.filter((task) => task?.workflow_recurrence_id == null),
				);

				return {
					...client,
					tasks: sorted_tasks,
				};
			});

			return (state = {
				...state,
				bulkClientTasks: [...client_tasks],
			});
		}
		default:
			return state;
	}
};

export default WorkSpaceReducer;
