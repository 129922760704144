import '../assets/styles/App.scss';


import * as searchActions from '../redux/actions/search-actions';

import {
	featureFlagTenants,
	getRoleName,
	isLocalhost,
	Member,
	standardPermissions,
} from '../utilities/utilities';
import Restricted from '../components/Permissions/Restricted';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import SearchModal from '../components/sidebar/SearchModal';
import ActionCenterModal from './ActionCenterModal';
import { useEffect, useMemo } from 'react';
import posthog from 'posthog-js';
import { Snackbar } from '@mui/material';
import ActionCenterLogo from '../components/sub_components/ActionCenterLogo';
import * as WorkSpaceActions from '../redux/actions/dashboard/workspaces-actions';
import Sentry from '../sentry/sentry';
import ErrorFound from './ErrorFound';
import * as authActions from '../redux/actions/auth-actions';
import * as ActionCenterActions from '../redux/actions/action-center';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import * as userActions from '../redux/actions/user-management.actions';
import { ReactComponent as CheckIcon } from '../assets/icons/CheckCircle.svg';
import { ReactComponent as ErrorIcon } from '../assets/icons/Info.svg';
import useTimeZone from '../hooks/useTimeZone';

import TaskModalHandler from './TaskModalHandler';
import { ProductFruits } from 'react-product-fruits';
import PostHogGroupIdentification from '../posthog/PostHogGroupIdentification';
import HotRefreshPolling from '../components/Others/HotRefreshPolling';
import DetectOnlineStatus from '../components/Others/DetectOnlineStatus';
import ActionSideBar from './ActionSideBar';
import AllRoutes from './AllRoutes';
import UsersPayrollExport from '../components/TimeReportsV2/UsersPayrollExport';
import PdfPreview from './PdfPreview';

function App() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.authReducer.user);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const actionCenterModal = useSelector((state) => state.authReducer.actionCenterModal);
	// const openActionCenterModal = useSelector(
	// 	(state) => state.workSpaceReducer.openActionCenterModal,
	// );
	const isTenantSubscribed = useSelector((state) => state.authReducer.isTenantSubscribed);
	const actionSideBarComponent = useSelector((state) => state.actionCenterReducer.actionSideBarComponent);

	const snackBar = useSelector((state) => state.workSpaceReducer.snackBar);

	const modalIsOpen = useSelector((state) => state.searchReducer.isSearchModalOpen);

	const toggleModal = () => {
		dispatch(searchActions.setModalState(!modalIsOpen));
	};

	useEffect(() => {
		const { formattedTimeZone } = useTimeZone();
		if (userDetails?.id) {
			dispatch(
				userActions.updateUserProfile({
					id: userDetails?.id,
					timezone: formattedTimeZone,
					email: userDetails?.email,
					full_name: userDetails?.full_name,
				}),
			);
		}
		dispatch(authActions.setFeatureFlag(featureFlagTenants?.includes(user?.tenantId)));
		if (!window.location.host.includes('localhost')) {
			if (userDetails?.id && user?.user_roles) {
				posthog.identify(String(userDetails?.id), {
					name: userDetails?.full_name,
					email: userDetails?.email,
					tenant_id: user?.tenantId,
					roles: user?.user_roles?.map((ur) => getRoleName(ur.role_id)),
				});
			}
		}
	}, [userDetails?.id, user]);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		dispatch(WorkSpaceActions.setSnackBarIsOpen(false, false, ''));
	};
	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(ActionCenterActions.getNotifications());
			dispatch(ActionCenterActions.getApprovalPending());
		}, 60000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const userInfo = useMemo(() => {
		if (!userDetails) {
			return null;
		}
		if (!user) {
			return null;
		}
		return {
			username: userDetails.email, // REQUIRED - any unique user identifier
			email: userDetails.email,
			firstname: userDetails.full_name,
			role: user?.userRole?.role_name,
			props: {
				roles: user?.user_roles?.map((ur) => getRoleName(ur.role_id)),
			},
			signUpAt: userDetails?.created_at,
		};
	}, [userDetails, user]);
    
	return (
		<Sentry.ErrorBoundary
			fallback={(error) => {
				dispatch(authActions.setLatestErrorId(error));
				return <ErrorFound />;
			}}
		>
			<div className='App h-auto' style={{ width: '100vw' }}>
				{!isLocalhost && userInfo?.username && (
					<ProductFruits
						workspaceCode={window._env_.REACT_APP_PRODUCT_FRUIT_KEY || 'NCAO1T7FLRs1N3BM'}
						language='en'
						user={userInfo}
					/>
				)}
				<TaskModalHandler />
				<PostHogGroupIdentification />
				<HotRefreshPolling />
				<DetectOnlineStatus />
				<div style={{ maxWidth: actionSideBarComponent ? 'calc(100vw - 375px)' : '100vw' }}>
                    <AllRoutes />
				</div>
                <ActionSideBar />
				{actionCenterModal && isTenantSubscribed && (
					<>
						<div className='action-center'>
							<Restricted
								AllowedUsers={[...standardPermissions, Member.teamMemberId]}
								userTeamRole={user?.user_roles?.map((item) => item.role_id)}
							>
								{/* <SearchLogo modalIsOpen={modalIsOpen} /> */}
							</Restricted>

							<div>
								<ActionCenterLogo />
							</div>
						</div>
						<Modal
							onClose={toggleModal}
							open={modalIsOpen}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<SearchModal onClose={toggleModal} />
						</Modal>
						<ActionCenterModal />
					</>
				)}

				<Snackbar
					open={snackBar?.IsOpen}
					autoHideDuration={5000}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<div className='SnackBarAlert'>
						<div className='d-flex align-items-center' style={{ gap: '18px' }}>
							<div>
								{snackBar?.isSuccess ? (
									<CheckIcon width={24} height={24} />
								) : (
									<ErrorIcon width={24} height={24} />
								)}
							</div>
							<span className='BodyTwoLight text-left'>{snackBar?.message}</span>
						</div>
						<IconButton
							size='small'
							aria-label='close'
							color='inherit'
							onClick={handleClose}
						>
							<CloseIcon fontSize='small' />
						</IconButton>
					</div>
				</Snackbar>
			</div>
		</Sentry.ErrorBoundary>
	);
}

export default App;
