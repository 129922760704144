import { useDispatch, useSelector } from 'react-redux';
import FormButtonOne from '../../Buttons/FormButtonOne';
import { EditableDropdown } from '../../Inputs/EditableDropDown';
import {
	clientPaymentModes,
	clientPaymentTerms,
	draftInvoices,
	formatRateValue,
	generateStartInvoiceNumber,
	paymentModeConstants,
} from '../../../utilities/utilities';
import { clientPaymentTypes } from '../../../utilities/utilities';
import EditableDate from '../../Inputs/EditableDate';
import EditableText from '../../Inputs/EditableText';
import * as Actions from '../../../redux/actions/client-billing-actions';
import * as profileActions from '../../../redux/actions/company-profile';

import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from '../../Inputs/Select';
import EditableLocationText from '../../Inputs/EditableLocationText';
import { State } from 'country-state-city';
import PhoneNumberInput from '../../Inputs/PhoneNumberInput';

const ClientBilling = ({ onCancel, title, isCreate }) => {
	const dispatch = useDispatch();
	const client = useSelector((state) => state.clientReducer.client);

	const initialForm = {
		payment_mode: null,
		bill_start_date: null,
		payment_type: '',
		rate: null,
		email_from: '',
		billing_contact: {
			first_name: '',
			last_name: '',
			phone_no: '',
			email_to: '',
		},
		address: [
			{
				country: '',
				address: '',
				city: '',
				zip: '',
				state: '',
				floor: '',
				is_primary: true,
			},
		],

		payment_terms: '',
		invoice_automation: false,
		notes: '',
		automation_date: '',
		next_billing_date: '',
		start_invoice_no: generateStartInvoiceNumber(client?.id),
		end_billing_date: '',
	};
	const editClientDetailsTabs = [
		{ value: 'contact', name: 'Contact' },
		{ value: 'address', name: 'Address' },
		{ value: 'billing', name: 'Billing' },
		{ value: 'invoice', name: 'Invoice' },
		{ value: 'additional', name: 'Additional' },
	];
	const [form, setForm] = useState(initialForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isFormChanged, setIsFormChanged] = useState(false);

	const [isPhoneFormatted, setIsPhoneFormatted] = useState(client?.is_phone_number_formated);

	const [currentTab, setCurrentTab] = useState('Contact');
	const [addCustomReferral, setAddCustomReferral] = useState(false);
	const clientBilling = useSelector((state) => state.clientBillingReducer.clientBilling);
	const activeClientbilling = clientBilling.find((item) => item?.is_active === true);
	const countries = useSelector((state) => state.profileReducer.countries);
	const states = useSelector((state) => state.profileReducer.states);

	// const PreviousBillingDate =
	// 	clientBilling.length > 1
	// 		? new Date(clientBilling[clientBilling.length - 2]?.bill_end_date).setDate(
	// 				new Date(clientBilling[clientBilling.length - 2]?.bill_end_date).getDate() + 1,
	// 		)
	// 		: new Date(0);

	const toggleLoader = (state) => {
		setIsLoading(state);
	};

	const handleDataChange = useCallback(
		(event) => {
			const { name, value } = event.target;

			const updatedForm = { ...form };

			if (name === 'email_from') {
				updatedForm.email_from = value;
			} else if (currentTab === 'Address') {
				updatedForm.address = [{ ...form.address[0], [name]: value }];
			} else if (
				[
					'payment_type',
					'payment_mode',
					'invoice_automation',
					'notes',
					'payment_terms',
					'start_invoice_no',
				].includes(name)
			) {
				updatedForm[name] = value;
			} else {
				updatedForm.billing_contact = { ...form.billing_contact, [name]: value };
			}

			if (name === 'rate') {
				const formattedValue = formatRateValue(value);
				updatedForm[name] = formattedValue;
			}
			setForm(updatedForm);
			setIsFormChanged(true);
		},
		[form, setForm, currentTab, setIsFormChanged],
	);

	const countryHandleChange = (event) => {
		event.preventDefault();

		const { name, value } = event.target;
		if (name === 'country') {
			setForm((prevState) => ({
				...prevState,
				address: [
					{
						...prevState.address[0],
						country: value,
						state: '',
						zip: '',
						address: '',
						city: '',
						floor: '',
					},
				],
			}));
		}
        setIsFormChanged(true);
	};

	const onAddressChange = (prop) => {
		if (prop?.target?.name === 'address') {
			setForm((prevState) => ({
				...prevState,
				address: [
					{
						...prevState.address[0],
						address: prop?.target?.value,
					},
				],
			}));
		} else {
			setForm((prevState) => ({
				...prevState,
				address: [
					{
						...prevState.address[0],
						address: prop.fullAddress,
						zip: prop.zipCode || '',
						state: prop.state
							? String(states.find((state) => state.label === prop.state)?.value)
							: '',
						city: prop?.city || '',
						floor: prop.suite || '',
					},
				],
			}));
		}
        setIsFormChanged(true);
	};

	const handleCountryPhone = (event, country, e, phoneNumber) => {
		let phone;
		if (isPhoneFormatted) {
			phone = phoneNumber;
		} else {
			phone = event.target.value;
		}
		setForm((prevForm) => ({
			...prevForm,
			billing_contact: {
				...prevForm.billing_contact,
				['phone_no']: phone,
			},
		}));
        setIsFormChanged(true);
	};

	const resetNumber = () => {
		setForm((prevForm) => ({
			...prevForm,
			billing_contact: {
				...prevForm.billing_contact,
				['phone_no']: '',
			},
		}));
        setIsFormChanged(true);
	};

	const toggleFormatting = (formatting) => {
		setIsPhoneFormatted(formatting);
	};

	const onDateChange = (date, name) => {
		const updatedForm = {
			...form,
			[name]: date,
		};
		setForm(updatedForm);
		setIsFormChanged(true);
		if (name === 'next_billing_date') {
			const endBillingDate = calculateEndBillingDate(
				updatedForm?.next_billing_date,
				form?.payment_mode,
			);
			setForm({
				...updatedForm,
				end_billing_date: endBillingDate,
			});
		}
	};

	console.log(form, 'form12345');

	const calculateEndBillingDate = (nextBillingDate, paymentMode) => {
		if (!nextBillingDate || !paymentMode) return null;

		const nextDate = moment(nextBillingDate);
		switch (paymentMode) {
			case paymentModeConstants.WEEKLY:
				return nextDate.add(1, 'week').subtract(1, 'day').toDate();
			case paymentModeConstants.MONTHLY:
				return nextDate.add(1, 'month').subtract(1, 'day').toDate();
			case paymentModeConstants.QUARTERLY:
				return nextDate.add(1, 'quarter').subtract(1, 'day').toDate();
			case paymentModeConstants.YEARLY:
				return nextDate.add(1, 'year').subtract(1, 'day').toDate();
			default:
				return null;
		}
	};

	const onSave = (e) => {
		e.preventDefault();

		if (isFormChanged) {
			var tempData = form;
			toggleLoader(true);
			const parsedForm = {
				...tempData,
				rate:
					typeof tempData?.rate === 'string'
						? parseFloat(tempData?.rate.replace(/,/g, '')) || 0.0
						: tempData?.rate || 0.0,
			};

			setForm(parsedForm);

			tempData = {
				payment_type: form?.payment_type,
				payment_mode: form?.payment_mode,
				rate:
					typeof parsedForm?.rate === 'string'
						? parseFloat(parsedForm?.rate) || 0.0
						: parsedForm?.rate || 0.0,
				client_id: client?.id,
				bill_start_date: parsedForm?.bill_start_date
					? moment(parsedForm?.bill_start_date).format('MM-DD-YYYY')
					: '',
				payment_terms: parsedForm?.payment_terms || '',
				invoice_automation: JSON.parse(parsedForm?.invoice_automation),
				automation_date: parsedForm?.automation_date
					? moment(parsedForm?.automation_date).format('MM-DD-YYYY')
					: '',
				next_billing_date: parsedForm?.next_billing_date
					? moment(parsedForm?.next_billing_date).format('MM-DD-YYYY')
					: '',
				notes: parsedForm?.notes || '',
				// email_from: parsedForm?.email_from || '',
				start_invoice_no: parsedForm?.start_invoice_no || '',
				billing_contact: {
					first_name: parsedForm?.billing_contact.first_name || '',
					last_name: parsedForm?.billing_contact.last_name || '',
					email_to: parsedForm?.billing_contact.email_to || '',
					phone_no: parsedForm?.billing_contact.phone_no || '',
				},
				address: [
					{
						country: parsedForm?.address[0]?.country || '',
						street: parsedForm?.address[0]?.address || '',
						city: parsedForm?.address[0]?.city || '',
						zip: parsedForm?.address[0]?.zip || '',
						state: parsedForm?.address[0]?.state || '',
						floor: parsedForm?.address[0]?.floor || '',
						is_primary: parsedForm?.address[0]?.is_primary || false,
					},
				],
			};
			dispatch(Actions.addClientBilling(tempData, onCancel, toggleLoader));
		} else {
			onCancel();
		}
	};

	useEffect(() => {
		dispatch(
			profileActions.getCountryStates(State.getStatesOfCountry(form?.address[0]?.country)),
		);
	}, [form?.address[0]?.country]);

	useMemo(() => {
		if (activeClientbilling) {
			const formattedForm = {
				...activeClientbilling,
				rate: `${activeClientbilling?.rate?.toLocaleString() || '0'}`,
				bill_start_date: moment.utc(activeClientbilling?.bill_start_date),
				next_billing_date: moment.utc(activeClientbilling?.next_billing_date),
				automation_date: moment.utc(activeClientbilling?.automation_date),
			};
			setForm(formattedForm);
		}
	}, [activeClientbilling]);

	useMemo(() => {
		if (isCreate) {
			setForm(initialForm);
		}
	}, [isCreate]);

	return (
		<form
			key={isCreate ? 'create' : 'update'}
			onSubmit={onSave}
			autoComplete='off'
			className='custom-referrel-modal'
		>
			<div style={{ maxHeight: '80vh', overflow: 'auto' }} className='px-3'>
				{!addCustomReferral && (
					<div className='client-details-inner-body '>
						<div className=' my-task-types pb-1'>
							{editClientDetailsTabs.map((item, i) => {
								return (
									<span
										key={i}
										className={`dark-text  my-task-type ${
											item.name == currentTab && 'active-form-tab'
										} `}
										onClick={() => setCurrentTab(item.name)}
									>
										{item.name}
									</span>
								);
							})}
						</div>

						<div></div>
					</div>
				)}

				{currentTab === 'Contact' && (
					<div>
						<div className='improved-input-designs pb-3'>
							<label className=''>First Name</label>
							<input
								className='w-100'
								type='text'
								name='first_name'
								// required
								value={form?.billing_contact?.first_name || ''}
								onChange={handleDataChange}
							/>
						</div>
						<div className='improved-input-designs pb-3'>
							<label>Last Name</label>
							<input
								className='w-100'
								type='text'
								name='last_name'
								// required
								value={form?.billing_contact?.last_name || ''}
								onChange={handleDataChange}
							/>
						</div>
						<div className='phone-number-container pb-3'>
							<label className='BodyTwoLight text-left m-0'>Phone</label>
							<PhoneNumberInput
								value={form?.billing_contact?.phone_no}
								handleChange={handleCountryPhone}
								resetNumber={resetNumber}
								isPhoneFormatted={isPhoneFormatted}
								toggleFormatting={toggleFormatting}
							/>
							{/* <input
								className='w-100'
								type='number'
								name='phone_no'
								pattern='^\(\d{3}\)\s\d{3}-\d{4}$'
								// required
								value={form?.billing_contact?.phone_no || ''}
								onChange={handleDataChange}
							/> */}
						</div>
						<div className='improved-input-designs pb-3'>
							<label>Email</label>
							<input
								className='w-100'
								type='email'
								name='email_to'
								// required
								value={form?.billing_contact?.email_to || ''}
								onChange={handleDataChange}
							/>
						</div>
					</div>
				)}
				{currentTab === 'Address' && (
					<div>
						<div className='improved-input-designs pb-3'>
							<label>Country</label>
							<Select
								value={{
									value: form?.address[0]?.country || '',
								}}
								options={countries}
								name='country'
								onChange={countryHandleChange}
							/>
						</div>
						<div className='improved-input-designs pb-3'>
							<label>Street</label>
							<EditableLocationText
								name='address'
								placeSelected={onAddressChange}
								value={form?.address[0]?.address || ''}
								onChange={onAddressChange}
								Edit={true}
								countryAbbr={form?.address[0]?.country || ''}
							/>
						</div>
						<div className='improved-input-designs pb-3'>
							<label>Floor/Suite</label>
							<input
								className='w-100'
								type='text'
								name='floor'
								value={form?.address[0]?.floor || ''}
								onChange={handleDataChange}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label>City/Town</label>
							<input
								className='w-100'
								type='text'
								name='city'
								value={form?.address[0]?.city || ''}
								onChange={handleDataChange}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label>State/Province/Region</label>
							<Select
								value={{ value: form?.address[0]?.state || '' }}
								options={states}
								name='state'
								onChange={handleDataChange}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label>Zip Code/Postal Code</label>
							<input
								className='w-100'
								type='text'
								name='zip'
								value={form?.address[0]?.zip || ''}
								onChange={handleDataChange}
							/>
						</div>
					</div>
				)}

				{currentTab === 'Billing' && (
					<div>
						<div
							style={{
								height: '100%',
								boxSizing: 'border-box',
								overflow: 'auto',
							}}
						>
							<div
								className='UserAdd-Form d-flex flex-column pr-2 pb-4'
								style={{ gap: '16px', height: 'inherit' }}
							>
								<EditableDropdown
									Edit={true}
									onChange={handleDataChange}
									label='Payment Type'
									name='payment_type'
									selectClassName='white-bg'
									className={'text-left text-capitalize'}
									value={clientPaymentTypes?.find(
										(item) => item.value == form?.payment_type,
									)}
									options={clientPaymentTypes}
									// required={true}
								/>

								<EditableDropdown
									Edit={true}
									onChange={handleDataChange}
									label='Payment Frequency'
									name='payment_mode'
									selectClassName='white-bg'
									className={`text-left text-capitalize ${
										form?.payment_type ? 'require' : ''
									}`}
									value={clientPaymentModes?.find(
										(item) => item.value === form?.payment_mode,
									)}
									options={clientPaymentModes}
									required={form?.payment_type ? true : false}
								/>

								<EditableDropdown
									Edit={true}
									name='payment_terms'
									label='Payment Terms'
									selectClassName='white-bg'
									className='text-left text-capitalize'
									value={clientPaymentTerms?.find(
										(item) => item.value === form?.payment_terms,
									)}
									options={clientPaymentTerms}
									onChange={handleDataChange}
									// required={true}
								/>

								<EditableText
									Class='hide-number-arrows'
									onChange={handleDataChange}
									label={`Rate`}
									address
									labelClass={`text-capitalize ${
										form?.payment_type ? 'require' : ''
									}`}
									name='rate'
									value={`$${form?.rate ? form?.rate : 0}`}
									type='text'
									Edit={true}
									required={form?.payment_type ? true : false}

									// required={true}
								/>
								<EditableDate
									onChange={(date) => onDateChange(date, 'bill_start_date')}
									label='Rate Start Date'
									name='bill_start_date'
									value={form?.bill_start_date}
									type='date'
									Edit={true}
									labelClass={`text-capitalize ${
										form?.payment_type ? 'require' : ''
									}`}
									required={form?.payment_type ? true : false}
									// minDate={PreviousBillingDate}
									helperText={null}
								/>
							</div>
						</div>
					</div>
				)}
				{currentTab === 'Invoice' && (
					<div>
						<div className='improved-input-designs pb-3'>
							<label className=''>Autogenerate Drafts</label>
							<EditableDropdown
								Edit={true}
								name='invoice_automation'
								selectClassName='white-bg'
								className='text-left text-capitalize'
								value={draftInvoices?.find(
									(item) => item.value == JSON.parse(form?.invoice_automation),
								)}
								options={draftInvoices}
								onChange={handleDataChange}
								// required={true}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label
								className={`${
									JSON.parse(form?.invoice_automation) == true ? 'require' : ''
								}`}
							>
								Start Billing Date
							</label>
							<EditableDate
								onChange={(date) => onDateChange(date, 'next_billing_date')}
								name='next_billing_date'
								value={form?.next_billing_date}
								labelClass='text-capitalize'
								type='date'
								Edit={true}
								helperText={null}
								required={
									JSON.parse(form?.invoice_automation) == true ? true : false
								}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label>End Billing Date</label>
							<EditableDate
								onChange={(date) => onDateChange(date, 'end_billing_date')}
								name='end_billing_date'
								value={form?.end_billing_date}
								labelClass='text-capitalize'
								type='date'
								Edit={true}
								helperText={null}
								disabled={true}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label
								className={`${
									JSON.parse(form?.invoice_automation) == true ? 'require' : ''
								}`}
							>
								Next Invoice Date
							</label>
							<EditableDate
								onChange={(date) => onDateChange(date, 'automation_date')}
								name='automation_date'
								value={form?.automation_date}
								labelClass='text-capitalize'
								type='date'
								Edit={true}
								// minDate={PreviousBillingDate}
								minDate={moment().add(1, 'day')}
								helperText={null}
								required={
									JSON.parse(form?.invoice_automation) == true ? true : false
								}
							/>
						</div>
						<div className='improved-input-designs pb-3'>
							<label className='require'>Invoice Prefix</label>
							<input
								className='w-100'
								type='number'
								name='start_invoice_no'
								value={form?.start_invoice_no || ''}
								onChange={handleDataChange}
								required
							/>
						</div>

						{/* <div className='improved-input-designs pb-3'>
							<label>Email From</label>
							<input
								className='w-100'
								type='email'
								name='email_from'
								value={form?.email_from || ''}
								onChange={handleDataChange}
							/>
						</div> */}
					</div>
				)}
				{currentTab === 'Additional' && (
					<div>
						<div className='improved-input-designs pb-3'>
							<label>Notes</label>
							<textarea
								className='w-100'
								name='notes'
								value={form?.notes || ''}
								onChange={handleDataChange}
							/>
						</div>
					</div>
				)}
			</div>

			<div style={{ height: '10%' }} className='my-task-form-actions'>
				<FormButtonOne onClick={onCancel} type='button' className='secondary-btn-light'>
					Close
				</FormButtonOne>
				<FormButtonOne loading={isLoading} type='submit' id='Element'>
					{title}
				</FormButtonOne>
			</div>
		</form>
	);
};

export default ClientBilling;
